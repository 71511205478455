<template>
  <ul
    ref="skipLinks"
    tabindex="-1"
  >
    <li>
      <a
        :href="skipLinkMainHash"
        :class="[$style.skipLink, 'dark']"
      >
        {{ $t('nav.skipLink.main.link') }}
      </a>
    </li>
  </ul>
  <div :class="$style.arena">
    <div
      ref="surfaces"
      :class="[$style.surfaces, showNav ? $style.showNav : '']"
    >
      <template v-if="showChrome">
        <template v-if="mobile">
          <button
            v-if="showNav"
            :class="$style.shield"
            @click="showNav = false"
          ></button>
          <transition name="nav">
            <FocusTrap
              v-if="showNav"
              @deactivate="showNav = false"
            >
              <NavigationSidebar
                :class="$style.nav"
                :ntcAvailable="ntcAvailable"
                style="z-index: unset"
              />
            </FocusTrap>
          </transition>
        </template>
        <NavigationSidebar
          v-else
          :class="$style.nav"
          :ntcAvailable="ntcAvailable"
          :showSurvey="showSurvey"
          @close-survey="showSurvey = false"
        />
        <HeaderBar
          :class="$style.header"
          @show-nav="showNav = true"
        />
      </template>
      <router-view v-slot="{ Component }">
        <transition :name="transitionName">
          <component
            :is="Component"
            :class="$style.surface"
          />
        </transition>
        <Toast />
      </router-view>
    </div>
    <Overlay
      :show="showDebug"
      :transition="narrow ? 'down' : 'up'"
      @close="showDebug = false"
    >
      <DebugConsole />
    </Overlay>
    <Loading :class="$style.arenaTitleLoading" />
    <GdprModal
      v-if="shouldShowSettings"
      :defaultSetting="defaultSetting"
      @submit="saveSettings"
      @close="hideSettings"
    />
    <NewRelease />
    <NtcPrompt
      v-if="shouldShowNtc"
      :providerId="providerId"
      @submit="submitNtc"
      @close="hideNtc"
    />
  </div>
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { Constants } from 'app/base/constants';
import DebugConsole from 'app/components/DebugConsole.vue';
import FocusTrap from 'app/components/FocusTrap.vue';
import GdprModal from 'app/components/GdprModal.vue';
import HeaderBar from 'app/components/HeaderBar.vue';
import Loading from 'app/components/Loading.vue';
import NavigationSidebar from 'app/components/NavigationSidebar.vue';
import NewRelease from 'app/components/NewRelease.vue';
import NtcPrompt from 'app/components/NtcPrompt.vue';
import Overlay from 'app/components/Overlay.vue';
import Toast from 'app/components/Toast.vue';
import { buildCopyJobContext } from 'app/contexts/copy-job-context';
import { providePrivacySettingsContext } from 'app/contexts/privacy-settings-context';
import { useNtcContext } from 'app/contexts/use-ntc-context';
import { useAlertGeneration } from 'app/functions/use-alert-generation';
import { useAlertsWithMetadata } from 'app/functions/use-alerts-with-metadata';
import { useSubscriptionsWithMetadata } from 'app/functions/use-subscriptions-with-metadata';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { AlertsWithMetadataSymbol, CopyJobContextSymbol, NtcContextSymbol, SkipLinkMainSymbol, SubscriptionsWithMetadataSymbol } from 'app/keys/injection-keys';
import { Timeline } from 'app/router/constants';
import Quirks from 'lib/gala/src/quirks';
import { computed, defineComponent, onMounted, provide, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    DebugConsole,
    FocusTrap,
    Loading,
    HeaderBar,
    NavigationSidebar,
    NewRelease,
    NtcPrompt,
    Overlay,
    GdprModal,
    Toast
},
  beforeRouteUpdate(to, from, next) {
    if (Quirks.ask('ios-safari')) {
      this.transitionName = '';
    } else {
      if (to.meta?.timeline && from && (to.meta.timeline === from.meta?.timeline)) {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        this.transitionName = toDepth === fromDepth
          ? ''
          : toDepth < fromDepth
            ? 'back'
            : 'forward';
      } else {
        this.transitionName = '';
      }
    }

    next();
  },
  setup: (_, ctx) => {
    const surfaces = ref<HTMLElement | null>(null);

    const transitionName = ref('forward');

    const route = useRoute();

    // Skip link
    const skipLinks = ref<HTMLElement | null>(null);
    const skipLinkMainHash = '#skip-link-main-content';
    provide(SkipLinkMainSymbol, skipLinkMainHash);

    const ntcContext = useNtcContext();
    const { shouldShowPrompt: shouldShowNtc, ntcAvailable, providerId, submit: submitNtc, hide: hideNtc } = ntcContext;
    provide(NtcContextSymbol, ntcContext);

    const subscriptionsWithMetadataContext = useSubscriptionsWithMetadata();
    provide(SubscriptionsWithMetadataSymbol, subscriptionsWithMetadataContext);
    const alertsWithMetadataContext = useAlertsWithMetadata();
    provide(AlertsWithMetadataSymbol, alertsWithMetadataContext);
    const { startAlertGeneration } = useAlertGeneration(subscriptionsWithMetadataContext, alertsWithMetadataContext);
    const startAlertGenerationSoon = C.debounce(() => { startAlertGeneration(); }, 5 * C.MS_SECOND);

    if (APP.patron.accountId) {
      startAlertGenerationSoon();
    } else {
      APP.events.on('patron:accountId:acquired', startAlertGenerationSoon);
    }

    const copyJobContext = buildCopyJobContext();
    provide(CopyJobContextSymbol, copyJobContext);

    if (APP.patron.accountId) {
      copyJobContext.startSync();
    } else {
      APP.events.on('patron:accountId:acquired', () => copyJobContext.startSync());
    }

    onMounted(() => {
      if (!surfaces.value) { return; }

      // ShellNone uses this to attach the Bifocal view.
      APP.arena.surface = surfaces.value;
      APP.events.dispatch('arena:ready');


      APP.events.on('msg:ui:scroll-to-top', () => APP.arena.scroller?.scrollToTop());

      skipLinks.value?.focus();
    });

    const { windowWidth } = useWindowSize();
    const mobile = computed(() => windowWidth.value <= Breakpoint.HideNav);
    const narrow = computed(() => windowWidth.value <= Breakpoint.Narrow);
    const showNav = ref(false);

    const showChrome = ref(false);
    watch(route, (to) => {
      APP.arena.scroller?.announce();
      showChrome.value = to.meta?.timeline !== Timeline.Onboarding;
      showNav.value = false;
      if (to.hash !== skipLinkMainHash) {
        setTimeout(() => { skipLinks.value?.focus(); }, 1);
      }
    }, { immediate: true });

    const showDebug = ref(false);
    APP.events.on('show:debug', () => showDebug.value = true);

    const showSurvey = ref(false);
    const surveyAskAgainDate = APP.bank.get('survey:ask-again');
    if (!surveyAskAgainDate || new Date() > new Date(surveyAskAgainDate)) {
      onMounted(() => {
        setTimeout(() => showSurvey.value = true, Constants.SURVEY_TRIGGER_MILLISECONDS);
      });
    }

    const {
      shouldShowSettings,
      defaultSetting,
      showSettings,
      hideSettings,
      saveSettings
    } = providePrivacySettingsContext();

    // Automatically show the GDPR modal if necessary
    APP.tracking.bankStart.then((allowed) => {
      if (allowed === undefined) {
        showSettings();
      }
    });

    return {
      mobile,
      narrow,
      transitionName,
      showChrome,
      showDebug,
      showNav,
      showSurvey,
      skipLinks,
      skipLinkMainHash,
      surfaces,

      shouldShowSettings,
      defaultSetting,
      hideSettings,
      saveSettings,

      shouldShowNtc,
      ntcAvailable,
      providerId,
      submitNtc,
      hideNtc
    };
  }
});
</script>

<style lang="less" module>

@import '../../app/views/core/base.less';

.arena {
  opacity: 1;
}

.skip-link {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  composes: focus-outline from global;
}

.skip-link:focus {
  position: fixed;
  left: 0.25rem;
  right: 0.25rem;
  top: 0.25rem;
  width: auto;
  height: auto;
  padding: 1rem;
  background-color: var(--c-primary-red);
  color: var(--c-white);
  z-index: 500;
}

.surfaces {
  display: grid;

  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: @px-vp-hide-nav) {
    .header,
    .surface {
      grid-column: 1 / span end;
      transition: transform linear 180ms;
    }

    .nav {
      box-shadow: inset -10px 0 10px @c-shadow;
    }

    &.show-nav {
      .header,
      .surface {
        transform: translateX(@px-nav-width);
      }
    }
  }

  .shield {
    grid-column: 2;
    grid-row: 1 / span end;
    width: 100%;
    height: 100%;
    z-index: 35;
  }
}

.nav {
  grid-column: 1;
  grid-row: 1 / end;
  z-index: 50;
  box-shadow: 10px 0px 10px @c-shadow;
  width: @px-nav-width;
  box-sizing: border-box;
}

.surface {
  grid-row: 2;
  grid-column: 2;
}

.header {
  grid-row: 1;
  grid-column: 2;
  z-index: 30;
  background-color: var(--c-darkest-blue);
}

// LoadingBox overrides.
.arena-title-loading {
  display: none;
}

// At the arena level, the loading component is shown when a title sets specific global classes
:global(.data-book_opening),
:global(.data-book_reopening) {
  .arena-title-loading {
    display: block;
  }
}

:global {
  .forward-enter-active,
  .forward-leave-active,
  .back-leave-active,
  .back-enter-active {
    transition: transform 260ms ease-out;
  }
  .forward-enter-from,
  .back-leave-to {
    transform: translateX(100%);
  }
  .back-enter-from,
  .forward-leave-to {
    transform: translateX(-100%);
  }
  .nav-enter-from,
  .nav-leave-to {
    transform: translateX(-100%);
  }
  .nav-enter-active,
  .nav-leave-active {
    transition: transform 220ms ease-out;
  }
}
</style>
