import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchThisTitleForm = _resolveComponent("SearchThisTitleForm")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_SearchThisTitleResultList = _resolveComponent("SearchThisTitleResultList")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createVNode(_component_SearchThisTitleForm, {
      item: _ctx.item,
      query: _ctx.request.query,
      scope: _ctx.request.scope,
      type: _ctx.request.type,
      class: _normalizeClass(_ctx.$style.form),
      onSearch: _ctx.onSearch
    }, null, 8, ["item", "query", "scope", "type", "class", "onSearch"]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(_ctx.$style.error)
        }, _toDisplayString(_ctx.$t('general.error')), 3))
      : (!_ctx.results)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            name: "spinner",
            class: _normalizeClass(_ctx.$style.loading),
            "aria-label": _ctx.$t('general.loading')
          }, null, 8, ["class", "aria-label"]))
        : (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(_ctx.$style.info)
          }, [
            _createElementVNode("p", { innerHTML: _ctx.resultsDescription }, null, 8, _hoisted_1)
          ], 2)),
    (_ctx.results && _ctx.results.hits.length > 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
          _createVNode(_component_SearchThisTitleResultList, {
            results: _ctx.results.hits,
            titles: _ctx.titles,
            class: _normalizeClass(_ctx.$style.results),
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
          }, null, 8, ["results", "titles", "class"]),
          (_ctx.results.totalPages > 1)
            ? (_openBlock(), _createBlock(_component_Pagination, {
                key: 0,
                currentPage: _ctx.request.page,
                totalPages: _ctx.results.totalPages,
                "onUpdate:currentPage": _ctx.onPage
              }, null, 8, ["currentPage", "totalPages", "onUpdate:currentPage"]))
            : _createCommentVNode("", true)
        ], 64))
      : (_ctx.results && _ctx.results.hits.length === 0)
        ? (_openBlock(), _createElementBlock("p", {
            key: 4,
            class: _normalizeClass(_ctx.$style.noMatches)
          }, _toDisplayString(_ctx.$t('searchThisTitle.noMatches')), 3))
        : _createCommentVNode("", true)
  ], 2))
}