<template>
  <Surface>
    <LoadingPage
      v-if="loadingState.state === 'loading'"
      :includeToolbar="true"
    />
    <Page
      v-else
      :header="$t('subjects.header')"
      :useBackToTop="true"
    >
      <template #toolbar>
        <div :class="$style.toolbar">
          <TextFilter
            v-model="textFilter"
            :label="$t('subjects.textFilter')"
          />
          <SortSelect
            v-model="selectedSort"
            :options="sortOptions"
            optionLabel="label"
            optionKey="label"
          />
        </div>
      </template>

      <template #default>
        <TabView
          v-if="loadingState.state === 'ready'"
          :tabs="tabs"
          :aria-label="$t('subjects.header')"
          @tab="(val) => currentTabId = val"
        >
          <template
            v-for="tab in tabs"
            #[`panel-${tab.id}`]
          >
            <template v-if="tab.count > 0">
              <SubjectsList
                v-for="list in tab.lists"
                :key="list.id"
                :class="$style.list"
                headerTag="h2"
                :label="tab.id === 'all' ? $t(list.label) : undefined"
                :subjects="list.items"
              />
            </template>
            <template v-else>
              <EmptyState
                :key="tab.id"
                :class="$style.empty"
                :header="$t('subjects.emptystate.header')"
                :content="tab.id == 'all' ? $t('subjects.emptystate.contentAll') : $t('subjects.emptystate.contentCategory')"
              />
            </template>
          </template>
        </TabView>
        <div v-else>
          {{ $t('subjects.error') }}
        </div>
      </template>
    </Page>
  </Surface>
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import EmptyState from 'app/components/EmptyState.vue';
import LoadingPage from 'app/components/LoadingPage.vue';
import Page from 'app/components/Page.vue';
import SortSelect from 'app/components/SortSelect.vue';
import SubjectsList from 'app/components/SubjectsList.vue';
import Surface from 'app/components/Surface.vue';
import TabView from 'app/components/TabView.vue';
import TextFilter from 'app/components/TextFilter.vue';
import { watchTextFiltering } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { SubjectCategory, SubjectCollection, fromTitleList } from 'app/models/subject';
import { computed, defineComponent, onMounted, ref } from 'vue';


type LoadingState = {
  state: 'loading';
} | {
  state: 'ready';
  data:  SubjectCollection[];
} | {
  state: 'failed';
};

type SubjectsListOptions = {
  id: SubjectCategory;
  label: string;
  items: SubjectCollection[];
};

type Tab = {
  id: 'all' | SubjectCategory;
  label: string;
  lists: SubjectsListOptions[];
  count: number;
};

export default defineComponent({
  name: 'Subjects',
  components: {
    EmptyState,
    SortSelect,
    Surface,
    SubjectsList,
    TabView,
    TextFilter,
    Page,
    LoadingPage
},
  props: {

  },
  setup: (props, ctx) => {
    const textFilter = ref('');

    const { t } = useI18n();
    const sortOptions = [
      {
        label: t('subjects.sort.alphabeticalAsc'),
        sort: (a: SubjectCollection, b: SubjectCollection) => {
          if (!a.name) { return -1; }
          if (!b.name) { return 1; }

          return a.name.localeCompare(b.name);
        }
      },
      {
        label: t('subjects.sort.alphabeticalDesc'),
        sort: (a: SubjectCollection, b: SubjectCollection) => {
          if (!a.name) { return -1; }
          if (!b.name) { return 1; }

          return b.name.localeCompare(a.name);
        }
      }
    ];
    const selectedSort = ref(sortOptions[0]);

    const loadingState = ref<LoadingState>({
      state: 'loading'
    });

    const subjects = computed<SubjectCollection[]>(() => {
      const raw = loadingState.value.state === 'ready' ? loadingState.value.data as SubjectCollection[] : [];
      const filtered = raw.filter((sub) => textFilter.value.length > 0
        ? sub.name.toLowerCase().includes(textFilter.value.toLowerCase())
        : true
      );
      const sorted = filtered.sort(selectedSort.value.sort);

      return sorted;
    });

    const subjectsByCategory = computed(() => {
      return subjects.value.reduce(
        (categorized, subject) => {
          categorized[subject.category].push(subject);

          return categorized;
        },
        {
          Jurisdiction: [],
          PracticeArea: [],
          Classification: [],
          Subject: []
        } as { [category in SubjectCategory]: SubjectCollection[] }
      );
    });

    const subjectsListOptions = computed(() => {
      const entries = Object.entries(subjectsByCategory.value) as [SubjectCategory, SubjectCollection[]][];

      return entries.reduce(
        (lists, [category, items]) => {
          lists[category] = {
            id: category,
            label: `general.subjectCategories.${category}`,
            items
          };

          return lists;
        },
        {} as { [category in SubjectCategory]: SubjectsListOptions }
      );
    });

    const tabs = computed<Tab[]>(() => {
      const { Jurisdiction, PracticeArea, Classification, Subject: subject } = subjectsListOptions.value;
      const all = [ Jurisdiction, PracticeArea, Classification, subject ];

      const options: Tab[] = [
        {
          id: 'all',
          label: t('subjects.categories.all'),
          lists: all.filter((listOptions) => listOptions.items.length > 0),
          count: all.reduce((sum, listOptions) => sum + listOptions.items.length, 0)
        }
      ];

      all.forEach((list) => {
        options.push({
          id: list.id,
          label: t(list.label),
          lists: [list],
          count: list.items.length
        });
      });

      return options;
    });

    const currentTabId = ref(tabs.value[0].id);

    const countForCurrentTab = computed(() => {
      const currentTab = tabs.value.find((tab) => tab.id === currentTabId.value);

      return t(`subjects.announceCount.${currentTab?.id}`, { COUNT: currentTab?.count });
    });

    watchTextFiltering(textFilter, countForCurrentTab);

    onMounted(async () => {
      loadingState.value = {
        state: 'loading'
      };

      try {
        await APP.library.freshen();
        await APP.library.catalog.freshen();

        const titleLists = APP.library.catalog.getSubjects(true);
        const mappedSubjects = titleLists.map(fromTitleList);

        loadingState.value = {
          state: 'ready',
          data: mappedSubjects
        };
      } catch {
        loadingState.value = {
          state: 'failed'
        };
      }
    });

    return {
      currentTabId,
      loadingState,
      selectedSort,
      sortOptions,
      tabs,
      textFilter
    };
  }
});
</script>

<style module>
.toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.list {
  margin-top: 2rem;
}

.list + .list {
  padding-top: 2rem;
  border-top: 1px solid var(--c-dark-gray);
}

.empty {
  margin-top: 2rem;
}

@media screen and (max-width: 360px /*@px-vp-very-narrow*/) {
  .toolbar {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>
