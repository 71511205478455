<template>
  <div :class="$style.container">
    <section :class="$style.welcomePanel">
      <LibraryLogo
        :library="library"
        :class="$style.logo"
      />

      <p
        v-if="state.libraryState === 'resolving'"
        :class="$style.loading"
      >
        <Icon
          name="spinner"
          :aria-label="$t('login.loading')"
        />
      </p>

      <FormError
        v-else-if="state.libraryState === 'error'"
        :class="$style.error"
        :contents="$t(state.libraryError)"
      />

      <p
        v-else-if="state.loginPage.state === 'loading'"
        :class="$style.loading"
      >
        <Icon
          name="spinner"
          :aria-label="$t('login.loading')"
        />
      </p>

      <FormError
        v-else-if="state.loginPage.state === 'error'"
        :class="$style.error"
        :contents="$t(state.loginPage.error)"
      />

      <template v-else>
        <h1 :class="$style.header">
          {{ $t('login.form.header') }}
        </h1>

        <LoginForm
          :page="state.loginPage.loginPage"
          :auth="state.loginPage.authenticationState"
          @cancel="cancelAuth"
          @submit="authenticate"
        />
      </template>
    </section>

    <p
      :class="$style.disclaimer"
      v-html="$t('login.form.disclaimer', {
        strong: (val) => { return `<strong>${val}</strong>`; },
        a: (val) => { return `<a href='https://www.lexisnexis.com/en-us/terms/publications-services-agreement.page' target='about:blank'>${val}</a>`; },
      })"
    ></p>
  </div>
</template>

<script lang="ts">
import { LoginFormSubmission } from 'app/base/interfaces';
import FormError from 'app/components/FormError.vue';
import LibraryLogo from 'app/components/LibraryLogo.vue';
import LoginForm from 'app/components/LoginForm.vue';
import { useAppEvents } from 'app/functions/use-app-events';
import { useAuthentication } from 'app/functions/use-authentication';
import { announcePageTitle } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { parseQueryString } from 'lib/common';
import { computed, defineComponent, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';

type GhostAuthOption = 'od' | 'ln' | 'none';

export default defineComponent({
  name: 'Login',
  components: {
    LibraryLogo,
    LoginForm,
    FormError
  },
  props: {
    libraryId: {
      type: String,
      required: true
    },
    callback: {
      type: String,
      default: undefined
    }
  },
  setup: (props, ctx) => {
    const { t } = useI18n();
    const route = useRoute();
    const auth = useAuthentication(
      props.libraryId,
      route.query.origination as string
    );

    onMounted(async () => {
      await auth.resolveLibrary();
      if (library.value) {
        announcePageTitle(t('login.form.ariaHeader', { library: library.value.name }));
      }
    });

    watch(auth.state, async (to, from) => {
      // Once we've resolved the library,
      // start fetching the login page.
      if (from.libraryState === 'resolving' && to.libraryState === 'resolved') {
        await auth.loadLoginPage(to.library);

        const ghostQuery = route.query.ghost as string;
        const ghost: GhostAuthOption = ghostQuery === 'od' || ghostQuery === 'ln'
          ? ghostQuery
          : 'none';

        if (ghost !== 'none') {
          authenticateGhost(ghost);

          return;
        }

        if (props.callback === 'callback') {
          const continuation = route.query.continuation as string;
          console.log('[AUTH] Completing external auth...');
          authenticateExternal(continuation);
        }
      }
    });

    const authenticate = async (data: LoginFormSubmission) => {
      const authResult = await auth.authenticate(data);

      if (authResult.state === 'success') {
        auth.completeAuthentication(authResult.response);
      }
    };

    const authenticateExternal = async (continuation: string) => {
      const authResult = await auth.authenticateExternal(continuation);

      if (authResult.state === 'success') {
        auth.completeAuthentication(authResult.response);
      }
    };

    const authenticateGhost = (ghostLogin: 'od' | 'ln') => {
      if (auth.state.value.libraryState === 'resolved'
        && auth.state.value.loginPage.state === 'loaded'
      ) {
        const loginPage = auth.state.value.loginPage.loginPage;
        if (ghostLogin === 'od' && loginPage.ghost) {
          authenticate({
            type: 'External',
            ilsName: loginPage.ghost.ilsName
          });
        } else if (ghostLogin === 'ln' && loginPage.ghostLexisNexis) {
          authenticate({
            type: 'External',
            ilsName: loginPage.ghostLexisNexis.ilsName
          });
        }
      }
    };

    useAppEvents({
      'msg:ui:oauth:callback': async ({ m: { url } }) => {
        if (auth.state.value.libraryState !== 'resolved') { return; }

        if (auth.state.value.loginPage.state !== 'loaded') {
          await auth.loadLoginPage(auth.state.value.library);
        }

        const params = parseQueryString(url.replace(/^[^\?]*/, ''));
        authenticateExternal(params.continuation);
      },
      'msg:ui:oauth:canceled': ({ m }) => {
        auth.cancelAuthentication();
      }
    });

    const cancelAuth = () => auth.cancelAuthentication();

    const library = computed(() => {
      if (auth.state.value.libraryState === 'resolved') {
        return auth.state.value.library;
      }

      return undefined;
    });

    return {
      authenticate,
      cancelAuth,
      library,
      state: auth.state
    };
  }
});
</script>

<style lang="less" module>
@import '../../app/views/core/base.less';

.loading {
  width: 20rem;
  height: 20rem;
  margin: auto;
  position: relative;

  svg {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.logo {
  height: auto;
  padding: 2rem;
  border-bottom: 1px solid @c-dark-gray;
  margin: auto;
  display: block;
  box-sizing: border-box;
}

.header {
  text-align: center;
  font-size: @fs-medium-head;
  font-weight: @fw-bold;
  margin: 2rem;
}

.error {
  margin: 1rem auto;
}

.disclaimer {
  margin: 3rem auto;
  color: @c-light-black;
  text-align: center;
  max-width: 20rem;

  a {
    .focus-outline;
    .pop-text();
    white-space: pre;
  }
}

@media screen and (max-width: @px-vp-narrow) {
  .container {
    width: 100%;
  }

  .loading {
    width: 100%;
  }

  .logo {
    max-width: 100%;
    padding: 2rem 0;
  }

  .disclaimer {
    padding: 0 1rem;
  }
}
</style>
