import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copy && _ctx.copy(...args)))
  }, [
    _createElementVNode("div", null, [
      _createVNode(_component_Icon, { name: "copy-link-to-clipboard" })
    ]),
    _createElementVNode("span", null, _toDisplayString(_ctx.buttonText), 1)
  ]))
}