<template>
  <div
    :class="$style.wrapper"
  >
    <label
      :for="id"
      :class="{
        [$style.label]: true,
        'visually-hidden': noLabel
      }"
    >
      {{ $t('general.textFilter.placeholder') }}
    </label>
    <div :class="$style.inputWrapper">
      <input
        :id="id"
        :value="internalInput"
        type="search"
        autocomplete="off"
        spellcheck="false"
        autocapitalize="off"
        :placeholder="labelWithDefault"
        :class="{
          [$style.input]: true,
          [$style.noLabel]: noLabel
        }"
        @keydown.enter.prevent=""
        @input="setInput"
      />
      <Icon
        name="search"
        :class="$style.icon"
      />
    </div>
  </div>
</template>

<script lang='ts'>
import { useI18n } from 'app/functions/use-i18n';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { generateUUID } from 'lib/common';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'TextFilter',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: undefined
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'update:modelValue'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();
    const labelWithDefault = computed(() => props.label || t('general.textFilter.placeholder'));

    const id = generateUUID();

    const { windowWidth } = useWindowSize();
    const noLabel = computed(() => (windowWidth.value < Breakpoint.Narrow) || props.hideLabel);

    const internalInput = computed({
      get: () => props.modelValue,
      set: (val) => ctx.emit('update:modelValue', val)
    });

    const setInput = (typingEvent: Event) => {
      if (typingEvent.target) {
        const target = typingEvent.target as HTMLInputElement;
        internalInput.value = target.value.trim();
      } else {
        internalInput.value = '';
      }
    };

    return {
      id,
      internalInput,
      labelWithDefault,
      noLabel,
      setInput
    };
  }
});
</script>

<style module>
.wrapper {
  display: flex;
  box-shadow: 0 1px 6px 0 var(--c-shadow);
  min-width: 20rem;
  max-height: 2.625rem;
  border-radius: var(--form-border-radius);
}

.label, .input-wrapper {
  border: 1px solid var(--c-dark-gray);
}

.label {
  background-color: var(--c-white);
  border-radius: var(--form-border-radius) 0 0 var(--form-border-radius);
  color: var(--c-medium-black);
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  border-right: 0;
}

.input-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  border-radius: 0 var(--form-border-radius) var(--form-border-radius) 0;
  background-color: var(--c-light-gray);
  width: 100%;
  align-items: center;
  position: relative;
}

.input {
  border: none;
  padding: 0 2.5rem 0 0.5rem;
  composes: focus-outline from global;
  composes: ellipsis from global;
  background-color: transparent;
  height: 100%;
  font-size: var(--fs-body);
}

.no-label {
  padding: 0.75rem 2.5rem 0.75rem 0.5rem;
  height: auto;
}

.label:global(.visually-hidden) + .input-wrapper {
  border-radius: var(--form-border-radius);
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--c-light-black);
  padding-right: 0.75rem;
  position: absolute;
  right: 0;
}

@media screen and (max-width: 768px /* px-vp-tablet */) {
  .wrapper {
    min-width: 16rem;
  }
}

@media screen and (max-width: 499px /* px-vp-narrow */) {
  .wrapper {
    min-width: 12rem;
  }
}

/* Remove magnifying glass icon in iOS / iPad */
.input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

</style>
