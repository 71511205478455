<template>
  <dialog
    ref="dialog"
    :class="[
      $style.dialog,
      { [$style.animationOpen]: animationOpen }
    ]"
    :style="styles"
    :aria-label="heading"
    @cancel="closeDialog"
    @click="closeDialog"
    @keydown.esc.prevent="closeDialog"
  >
    <div
      ref="container"
      :class="$style.container"
      @click="(e) => e.stopPropagation()"
    >
      <div :class="$style.wrapper">
        <Chatterbox />
        <header
          :class="$style.header"
        >
          <div class="notch"></div>
          <span :class="$style.backContainer">
            <button
              v-if="showBack"
              ref="backButton"
              :class="$style.back"
              :aria-label="$t('header.account.back')"
              @click="$emit('back')"
            >
              <Icon name="chevron-left" />
            </button>
          </span>
          <span :class="$style.headingContainer">
            <h2 :class="$style.heading">
              <Icon
                v-if="headingIcon"
                :name="headingIcon"
              />
              {{ heading }}
            </h2>
          </span>
          <span :class="$style.dismissContainer">
            <button
              ref="dismissButton"
              :class="$style.dismiss"
              :aria-label="$t('general.close')"
              @click="closeDialog"
            >
              <Icon name="dismiss" />
            </button>
          </span>
          <div :class="$style.extraContainer">
            <slot name="extra"></slot>
          </div>
        </header>
        <div :class="$style.content">
          <transition
            :name="showBack ? 'slide-in-right' : 'slide-in-left'"
            @after-enter="scrollTop"
          >
            <slot></slot>
          </transition>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script lang="ts">
import Chatterbox from 'app/components/Chatterbox.vue';
import { useDialog } from 'app/functions/use-dialog';
import { defineComponent, nextTick, onMounted, ref, SetupContext, watch } from 'vue';

export default defineComponent({
  components: {
    Chatterbox
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    showBack: {
      type: Boolean,
      required: true
    },
    headingIcon: {
      type: String,
      default: undefined
    }
  },
  emits: [
    'close',
    'back'
  ],
  setup: (props, ctx: SetupContext) => {
    const {
      animationOpen,
      closeDialog,
      dialog,
      styles
    } = useDialog(ctx);

    const container = ref<HTMLElement | null>(null);
    const backButton = ref<HTMLButtonElement | null>(null);
    const dismissButton = ref<HTMLButtonElement | null>(null);

    const scrollTop = () => {
      container.value?.scrollIntoView({ behavior: 'smooth' });
    };

    // Refocus when transitioning content
    watch(() => props.showBack, async (shown) => {
      await nextTick();

      if (shown) {
        backButton.value?.focus();
      } else {
        dismissButton.value?.focus();
      }
    });

    onMounted(scrollTop);

    return {
      animationOpen,
      backButton,
      closeDialog,
      container,
      dialog,
      dismissButton,
      scrollTop,
      styles
    };
  }
});
</script>

<style module>
.dialog {
  background-color: var(--c-white);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
  max-height: 70vh;
  border-radius: var(--form-border-radius);
  box-shadow: 0 2px 8px var(--c-darkest-gray);
  width: 26rem;

  /* These menus are forced to the top right, just below the header bar */
  position: fixed;
  top: 3.5rem;
  right: 1rem;
  inset-inline-start: unset;
}

.header {
  background-color: var(--c-white);
  position: sticky;
  top: 0;
  display: grid;
  grid-template-areas:
    "back heading dismiss"
    "extra extra extra";
  grid-template-columns: 1.5rem 1fr 1.5rem;
  align-items: center;
  padding: 1rem;
  z-index: 1;
}

.back-container {
  grid-area: back;
}

.heading-container {
  grid-area: heading;
  justify-self: center;
}

.dismiss-container {
  grid-area: dismiss;
}

.extra-container {
  grid-area: extra;
}

.heading {
  display: flex;
  align-items: center;
  font-weight: var(--fw-medium-bold);
}

.heading svg {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}

.back {
  line-height: 0;
  padding: 0;
}

.back svg {
  height: 1.5rem;
  width: 1.5rem;
}

.dismiss {
  line-height: 0;
  padding: 0.25rem;
}

.dismiss svg {
  height: 1rem;
  width: 1rem;
}

.back svg,
.heading svg,
.dismiss svg {
  fill: var(--c-light-black);
}

.content {
  padding: 0 1rem 1rem 1rem;
}

@media screen and (max-width: 1200px /* px-vp-hide-nav */) {
  .dialog {
    top: 0;
    left: 0;
    border-radius: 0 0 0.5rem 0.5rem;
    width: 100vw;
    max-width: unset; /* By default dialog has a max-width setting */
  }
}

/* Animation properties */

.dialog .container {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows var(--animation-duration);
}

.dialog .container .wrapper {
  min-height: 0;
}

.dialog.animation-open .container {
  grid-template-rows: 1fr;
}

.dialog.animation-open::backdrop {
  animation: backdrop-fade-in var(--animation-duration) ease forwards;
}

.dialog::backdrop {
  animation: backdrop-fade-out var(--animation-duration) ease forwards;
}

/* Animation keyframes */

@keyframes backdrop-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes backdrop-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>
