<template>
  <div :class="$style.container">
    <SearchThisTitleForm
      :item="item"
      :query="request.query"
      :scope="request.scope"
      :type="request.type"
      :class="$style.form"
      @search="onSearch"
    />
    <p
      v-if="error"
      :class="$style.error"
    >
      {{ $t('general.error') }}
    </p>
    <Icon
      v-else-if="!results"
      name="spinner"
      :class="$style.loading"
      :aria-label="$t('general.loading')"
    />
    <div
      v-else
      :class="$style.info"
    >
      <p v-html="resultsDescription"></p>
    </div>
    <template
      v-if="results && results.hits.length > 0"
    >
      <SearchThisTitleResultList
        :results="results.hits"
        :titles="titles"
        :class="$style.results"
        @close="$emit('close')"
      />
      <Pagination
        v-if="results.totalPages > 1"
        :currentPage="request.page"
        :totalPages="results.totalPages"
        @update:currentPage="onPage"
      />
    </template>
    <p
      v-else-if="results && results.hits.length === 0"
      :class="$style.noMatches"
    >
      {{ $t('searchThisTitle.noMatches') }}
    </p>
  </div>
</template>

<script lang="ts">
import { HudsonSearchResponse, SearchParameters, SearchThisTitleQuery } from 'app/base/hudson';
import { MediaType } from 'app/base/interfaces';
import Pagination from 'app/components/Pagination.vue';
import SearchThisTitleForm from 'app/components/SearchThisTitleForm.vue';
import SearchThisTitleResultList from 'app/components/SearchThisTitleResultList.vue';
import { announceMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { TitleRecord } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import { Dictionary } from 'lib/common/dictionary';
import { computed, defineComponent, watch } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'SearchThisTitleResults',
  components: {
    Pagination,
    SearchThisTitleForm,
    SearchThisTitleResultList
  },
  props: {
    item: {
      type: Object as () => {
        title: string;
        subtitle?: string;
        mediaType: MediaType | 'series';
        seriesId?: number;
        path: () => string;
      },
      required: true
    },
    results: {
      type: Object as () => HudsonSearchResponse | null,
      default: null
    },
    titles: {
      type: Object as () => Dictionary<TitleRecord>,
      default: () => ({})
    },
    error: {
      type: Boolean,
      default: false
    },
    request: {
      type: Object as () => SearchParameters,
      required: true
    }
  },
  emits: [
    'close',
    'page',
    'search'
  ],
  setup: (props, ctx) => {
    const { t } = useI18n();

    const onSearch = (params: SearchThisTitleQuery) => {
      ctx.emit('search', {
        scope: params.scope,
        query: params.query,
        type: params.type
      });
    };

    const onPage = (newPage: number) => {
      ctx.emit('page', newPage);
    };

    const router = useRouter();
    const path = computed(() => {
      const name = props.request.scope === 'series'
        ? RouteName.SetDetails
        : RouteName.TitleDetails;
      const params: Dictionary<string> =  props.request.scope === 'series'
        ? { seriesId: props.request.id }
        : { titleSlug: props.request.id };

      return router.resolve({
        name,
        params
      }).href;
    });

    const resultsDescriptionArgs = computed(() => {
      if (!props.results) {
        return {};
      }

      return {
        sections: props.results.totalHits,
        volumes: props.results.totalVolumes,
        type: props.request.type,
        title: props.item.title,
        subtitle: props.item.subtitle || '__MISSING_SUBTITLE'
      };
    });

    const resultsDescription = computed(() => {
      if (!props.results) {
        return '';
      }

      return t(`searchThisTitle.resultDescription.${props.request.scope}`, {
        ...resultsDescriptionArgs.value,
        a: (val: string) => `<a class='linked focus-outline' href='${path.value}'>${val}</a>`
      });
    });

    watch(() => props.results, () => {
      if (!props.results) { return; }

      announceMessage(t(`searchThisTitle.resultDescription.${props.request.scope}`, {
        ...resultsDescriptionArgs.value,
        a: (val: string) => val
      }));
    }, { immediate: true });

    watch(() => props.results, () => {
      if (!props.results) { return; }
      if (props.results.totalPages <= 1) { return; }

      announceMessage(t('list.pageChange', {
          page: props.request.page,
          total: props.results.totalPages
        }));
    }, { immediate: true });

    return {
      onPage,
      onSearch,
      path,
      resultsDescription
    };
  }
});
</script>

<style module>
.container {
  background-color: var(--c-white);
  text-align: left;
  white-space: normal;
}

.info {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0.5rem;
  border-bottom: 1px solid var(--c-dark-gray);
  color: var(--c-light-black);
}

.info a {
  color: var(--c-primary-blue);
}

.results {
  margin: 1.5rem 0;
}

.error,
.no-matches {
  margin: 1rem;
  text-align: center;
}

.loading {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 2rem auto;
}
</style>
