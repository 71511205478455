import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "visually-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "politeRegion",
      "aria-live": "polite"
    }, _toDisplayString(_ctx.politeMessage), 513),
    _createElementVNode("div", {
      ref: "assertiveRegion",
      "aria-live": "assertive"
    }, _toDisplayString(_ctx.assertiveMessage), 513)
  ]))
}