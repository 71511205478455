import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(_ctx.$style.selected),
    "aria-label": _ctx.$t('filters.ariaLabels.removeFilter', { NAME: _ctx.appliedOption.name }),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clicked', _ctx.appliedOption)))
  }, [
    (_ctx.optionType === 'color')
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(_ctx.$style.indicator),
          style: _normalizeStyle(`--highlight-color: var(--highlight-color-${_ctx.appliedOption.id})`)
        }, null, 6))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.appliedOption.name) + " ", 1),
    _createVNode(_component_Icon, {
      name: "dismiss",
      role: "presentation",
      class: _normalizeClass(_ctx.$style.dismiss)
    }, null, 8, ["class"])
  ], 10, _hoisted_1))
}