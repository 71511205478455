<template>
  <Page
    :header="$t('general.loading')"
    :announceHeader="false"
  >
    <template
      v-if="includeToolbar"
      #toolbar
    >
      <div :class="$style.toolbar"></div>
    </template>
    <template #default>
      <Icon
        :class="$style.icon"
        :aria-label="$t('general.loading')"
        name="spinner"
      />
    </template>
  </Page>
</template>

<script lang="ts">
import Page from 'app/components/Page.vue';
import { announceMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { defineComponent, onUnmounted } from 'vue';

export default defineComponent({
  name: 'LoadingPage',
  components: {
    Page
  },
  props: {
    includeToolbar: {
      type: Boolean,
      default: true
    }
  },
  setup: () => {
    const { t } = useI18n();

    // announce a loading message to screen readers if the page has taken longer than 2 seconds to load
    let timer = -1;
    timer = window.setTimeout(() => announceMessage(t('general.loading'), 'assertive'), 2000);

    onUnmounted(() => clearTimeout(timer));

  }
});
</script>

<style lang="less" module>
.toolbar {
  height: 40px;
}

.icon {
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 2rem auto;
}
</style>
