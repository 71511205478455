<template>
  <LibraryCodeForm @valid="onValidEntry" />
</template>

<script lang='ts'>
import LibraryCodeForm, { LibraryCodeEntry } from 'app/components/LibraryCodeForm.vue';
import { announcePageTitle } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { RouteName } from 'app/router/constants';
import { defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'LibraryCode',
  components: {
    LibraryCodeForm
  },
  setup: () => {
    const { t } = useI18n();
    const router = useRouter();
    const onValidEntry = (entry: LibraryCodeEntry) => {
      router.push({
        name: RouteName.Login,
        params: {
          libraryId: entry.code
        },
        query: {
          ghost: entry.ghost || 'none'
        }
      });
    };

    onMounted(() => {
      announcePageTitle(t('welcome.ariaHeader'));
    });

    return {
      onValidEntry
    };
  }
});
</script>
