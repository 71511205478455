<template>
  <Surface>
    <LoadingPage
      v-if="state.status === 'loading'"
    />

    <NotFound
      v-else-if="state.status === 'notfound'"
    />

    <Page
      v-else
      :header="state.pageTitle"
      :subtitle="state.subtitle"
      :title="state.title"
      :announceHeader="$t('annotations.display.header', { title: state.title.fullTitle() })"
      :useBackToTop="true"
    >
      <template #toolbar>
        <FilterButton
          :options="filterObjects"
          filterType="annotation"
          :iconOnly="mobile"
          @input="updatePath"
        />
        <TextFilter
          v-model="textFilter"
          :label="$t('annotations.textFilter')"
          :class="$style.textFilter"
        />
      </template>
      <template #default>
        <AnnotationDetailsCopyContent
          :titleSlug="titleSlug"
        />

        <AnnotationDetailsContent
          :annotations="detailsAnnotations"
          :title="state.title.title"
          :subtitle="state.title.subtitle"
          :mobile="mobile"
          :showingCount="showingCount"
          :selectAllState="selectAllState"
          :focusTargetId="pagingFocusTargetId"
          @annotations:update="onSelectedUpdate"
          @annotations:update:all="onSelectedUpdateAll"
        />

        <Pagination
          v-if="totalPages > 1"
          v-model:currentPage="currentPage"
          :class="$style.pagination"
          :totalPages="totalPages"
        />
      </template>
    </Page>
  </Surface>
</template>

<script lang="ts">
import { HighlightColorGroup } from 'app/base/constants';
import AnnotationDetailsContent, { AnnotationDetailsAnnotation } from 'app/components/AnnotationDetailsContent.vue';
import AnnotationDetailsCopyContent from 'app/components/AnnotationDetailsCopyContent.vue';
import FilterButton from 'app/components/FilterButton.vue';
import LoadingPage from 'app/components/LoadingPage.vue';
import Page from 'app/components/Page.vue';
import Pagination from 'app/components/Pagination.vue';
import Surface from 'app/components/Surface.vue';
import TextFilter from 'app/components/TextFilter.vue';
import { AnnotationFilters, getQueryParametersFromAnnotationFilters, useAnnotationFilters } from 'app/functions/use-annotation-filters';
import { announceMessage, watchFiltering, watchTextFiltering } from 'app/functions/use-chatterbox';
import { useCopyJobs } from 'app/functions/use-copy-jobs';
import { useCopyJobsState } from 'app/functions/use-copy-jobs-state';
import { useI18n } from 'app/functions/use-i18n';
import { normalizeDate } from 'app/functions/use-normalize-date';
import { usePriorReleases } from 'app/functions/use-prior-releases';
import { useReleaseFilter } from 'app/functions/use-release-filter';
import useSyncedAnnotations from 'app/functions/use-synced-annotations';
import { useTitle } from 'app/functions/use-title';
import { Breakpoint, useWindowSize } from 'app/functions/use-window-size';
import { ADAllowStTOption, ADCopyJobState, ADReleasesWithAnnotationsSymbol } from 'app/keys/injection-keys';
import { Annotation } from 'app/models/annotation';
import { FormCheckboxState } from 'app/models/form-checkbox-state';
import { Title } from 'app/models/title';
import { RouteName } from 'app/router/constants';
import NotFound from 'app/views/NotFound.vue';
import { generateUUID } from 'lib/common';
import { PropType, Ref, computed, defineComponent, provide, ref, watch, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

type State =
  {
    status: 'loading';
  }
  | {
    status: 'loaded';
    title: Title;
    releases: Title[];
    annotations: Annotation[];
    pageTitle: string;
    subtitle: string;
  }
  | {
    status: 'notfound';
  };


export default defineComponent({
    components: {
      Page,
      Surface,
      NotFound,
      AnnotationDetailsContent,
      LoadingPage,
      TextFilter,
      FilterButton,
      AnnotationDetailsCopyContent,
      Pagination
    },
    props: {
      titleSlug: {
        type: String,
        required: true
      },
      releaseFilters: {
        type: Array as PropType<string[]>,
        default: undefined
      },
      colorFilters: {
        type: Array as PropType<HighlightColorGroup[]>,
        default: undefined
      }
    },
    setup: (props, ctx) => {
      const { windowWidth } = useWindowSize();
      const mobile = computed(() => windowWidth.value <= Breakpoint.VeryWide);

      const pagingFocusTargetId = `focus-target-${generateUUID()}`;


      /*** Data fetching ***/

      const {
        annotations: allAnnotations,
        loading: annotationsLoading,
        error: annotationsError
      } = useSyncedAnnotations();

      const {
        title,
        loading: titleLoading,
        error: titleError
      } = useTitle(props.titleSlug);

      const {
        priorReleases,
        loading: priorReleasesLoading,
        error: priorReleasesError
      } = usePriorReleases(title as Ref<Title | null>);

      const router = useRouter();
      watch(title, () => {
        if (title.value?.lexisMetadata?.parent) {
          router.replace({ params: { titleSlug: title.value.lexisMetadata.parent } });
        }
      }, { immediate: true });

      const state = computed<State>(() => {
        const loading = annotationsLoading.value || titleLoading.value || priorReleasesLoading.value;
        if (loading) {
          return { status: 'loading' };
        }

        const missingData = !allAnnotations.value || !title.value || !priorReleases.value;
        const error = annotationsError.value || titleError.value || priorReleasesError.value;
        if (missingData || error) {
          return { status: 'notfound' };
        }

        const allReleases = [title.value, ...priorReleases.value];
        const annotations = allAnnotations.value!.filter((a) => a.titleSlug === props.titleSlug) as Annotation[];

        return {
          status: 'loaded',
          title: title.value,
          releases: allReleases,
          annotations,
          pageTitle: title.value.title,
          subtitle: title.value.subtitle
        } as State;
      });


      /*** Filtering and pagination ***/

      const annotationsForTitle = computed<Annotation[]>(() => state.value.status === 'loaded'
        ? state.value.annotations
        : []
      );

      const releases = computed<Title[]>(() => state.value.status === 'loaded'
        ? state.value.releases
        : []
      );

      const {
        textFilter,
        releaseFilter,
        colorFilter,
        currentPage,
        totalPages,
        filterObjects,
        selectableAnnotations,
        pageableAnnotations,
        annotationsToDisplay,
        displayCounts
      } = useAnnotationFilters(annotationsForTitle, releases);

      watchEffect(() => releaseFilter.value = props.releaseFilters);
      watchEffect(() => colorFilter.value = props.colorFilters);

      watch(currentPage, () => {
        const element = document.getElementById(pagingFocusTargetId);
        if (element) {
          element.focus();
        }
      });

      const { t } = useI18n();
      const showingCount = computed(() => t(
        'annotations.showingCount',
        {
          SHOWING: displayCounts.value.showing,
          TOTAL: displayCounts.value.total
        }
      ));

      const updatePath = (filters: AnnotationFilters) => {
        const newRoute = {
          name: RouteName.NoteDetails,
          query: {
            ...getQueryParametersFromAnnotationFilters(filters)
          }
        };

        router.replace(newRoute);

        shouldAnnounceCount.value = true;
      };

      const annouceCount = computed(() => t('annotations.announceCount', { COUNT: displayCounts.value.showing, TYPE: 'annotation' }));
      const { shouldAnnounceCount } = watchFiltering(displayCounts, annouceCount);
      watchTextFiltering(textFilter, annouceCount);


      /*** Selection and display ***/

      const selected = ref<Set<string>>(new Set());

      // Reset selected values when the release or color filters change
      watch(releaseFilter, () => selected.value.clear());
      watch(colorFilter, () => selected.value.clear());

      const selectAllState = computed<FormCheckboxState>(() => {
        return pageableAnnotations.value.every((a) => selected.value.has(a.uuid))
          ? 'on'
          : pageableAnnotations.value.some((a) => selected.value.has(a.uuid))
            ? 'partial'
            : 'off';
      });

      const onSelectedUpdate = (updated: AnnotationDetailsAnnotation[]) => {
        selected.value = new Set(updated.filter((a) => a.selected).map((a) => a.uuid));
      };
      const onSelectedUpdateAll = (isSelected: boolean) => {
        pageableAnnotations.value.forEach((a) => {
          if (isSelected) {
            selected.value.add(a.uuid);
          } else {
            selected.value.delete(a.uuid);
          }
        });
      };

      const detailsAnnotations = computed<AnnotationDetailsAnnotation[]>(() => {
        if (state.value.status !== 'loaded') { return []; }

        const parentTitleRecord = state.value.title;
        const titleReleases = state.value.releases;

        const releaseKey = (info: { release?: string; releaseDate?: string }) => `${info.release}::${normalizeDate(info.releaseDate)}`;
        const releaseMap = new Map<string, Title>(titleReleases.map((r) => [releaseKey(r.lexisMetadata), r]));

        const idsToShow = new Set(annotationsToDisplay.value.map((a) => a.uuid));

        return selectableAnnotations.value.map((a) => {
          const releaseTitleRecord = releaseMap.get(releaseKey(a));

          return {
            ...a,
            shown: idsToShow.has(a.uuid),
            selected: selected.value.has(a.uuid),
            parentTitleRecord,
            releaseTitleRecord
          };
        }).sort((a, b) => b.syncstamp - a.syncstamp);
      });


      /*** Annotation copying ***/

      provide(ADReleasesWithAnnotationsSymbol, computed(() => {
        const { getFilterObjects: getReleaseObjects } = useReleaseFilter(annotationsForTitle, releases);

        return getReleaseObjects(annotationsForTitle.value);
      }));

      const {
        copyJobs,
        loading: copyJobsLoading,
        error: copyJobsError
      } = useCopyJobs(props.titleSlug);

      const {
        copyJobsState
      } = useCopyJobsState(copyJobs, copyJobsLoading, copyJobsError);

      provide(ADCopyJobState, copyJobsState);

      provide(ADAllowStTOption, computed(() => {
        // Only Lexis published titles work with StT
        return state.value.status === 'loaded'
          ? state.value.title.isLexisPublished
            && state.value.title.hasODRFormat
            && state.value.title.mediaType === 'book'
          : false;
      }));

      watch(currentPage, () => {
        if (state.value.status === 'loaded' && totalPages.value > 1) {
          announceMessage(t('list.pageChange', {
            page: currentPage.value.toString(),
            total: totalPages.value.toString()
          }));
        }
      }, { immediate: true });

      return {
        currentPage,
        detailsAnnotations,
        filterObjects,
        mobile,
        onSelectedUpdate,
        onSelectedUpdateAll,
        pagingFocusTargetId,
        selectAllState,
        showingCount,
        state,
        textFilter,
        totalPages,
        updatePath
      };
    }
});
</script>

<style module>
.text-filter {
  margin-left: auto;
  max-width: 16rem;
}

.pagination {
  margin-top: 2.5rem;
}
</style>
