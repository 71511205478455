import { APP } from 'app/base/app';
import { Dictionary } from 'app/base/common';
import { parseSearchThisSetOptions } from 'app/base/hudson';
import { Sentry } from 'app/base/sentry';
import i18n from 'app/i18n/i18n';
import { subjectRoute } from 'app/models/subject';
import { Breadcrumb, breadcrumbTrail } from 'app/router/breadcrumb';
import { RouteName, Timeline } from 'app/router/constants';
import { setDocumentTitle } from 'app/router/document-title';
import { findActiveElement, setFocus } from 'app/router/focus-management';
import { parseLegacyCollectionParameters, parseLegacyListParameters, parseLegacySearchParameters } from 'app/router/list-query-mapper';
import { mapSearchThisTitleQueryParams, mapSearchThisTitleQueryString, mapSubjectFilters } from 'app/router/query-mapper';
import AnnotationDetails from 'app/views/AnnotationDetails.vue';
import Arena from 'app/views/Arena.vue';
import Browse from 'app/views/Browse.vue';
import CollectionDefinition from 'app/views/CollectionDefinition.vue';
import CopyReview from 'app/views/CopyReview.vue';
import Creator from 'app/views/Creator.vue';
import ExportQueue from 'app/views/ExportQueue.vue';
import Home from 'app/views/Home.vue';
import LegacyRedirectFailed from 'app/views/LegacyRedirectFailed.vue';
import LibraryCode from 'app/views/LibraryCode.vue';
import Login from 'app/views/Login.vue';
import MyBooks from 'app/views/MyBooks.vue';
import MySubscriptions from 'app/views/MySubscriptions.vue';
import Notes from 'app/views/Notes.vue';
import NotFound from 'app/views/NotFound.vue';
import Ntc from 'app/views/Ntc.vue';
import Open from 'app/views/Open.vue';
import Publisher from 'app/views/Publisher.vue';
import Redirect from 'app/views/Redirect.vue';
import Search from 'app/views/Search.vue';
import SeriesDetails from 'app/views/SeriesDetails.vue';
import SignedOut from 'app/views/SignedOut.vue';
import Subject from 'app/views/Subject.vue';
import Subjects from 'app/views/Subjects.vue';
import TagDetails from 'app/views/TagDetails.vue';
import Tags from 'app/views/Tags.vue';
import TitleDetails from 'app/views/TitleDetails.vue';
import Welcome from 'app/views/Welcome.vue';
import { nextTick } from 'vue';
import { createRouter, createWebHistory, RouteLocation, Router, RouteRecordRaw } from 'vue-router';


const routes: RouteRecordRaw[] = [
  {
    name: RouteName.Home,
    path: '/',
    redirect: () => {
      return APP?.library
        ? { name: RouteName.LibraryHome, params: { libraryId: APP.library.baseKey } }
        : { name: RouteName.Welcome };
    }
  },
  {
    name: RouteName.SignOut,
    path: '/sign-out',
    redirect: () => {
      APP.bank.wipe();
      // Prevent any other data from being pushed into the bank
      APP.bank.freeze();

      // For now - tell shell to remove all roster info
      APP.shell.transmit('roster:wipe:all');
      APP.shell.transmit('title:info');

      // Sign the user out (waiting for a while to give the bank and rosters events time to occur)
      setTimeout(async () => {
        await APP.scribe.sendOnLogout();
        await APP.patron.reset();
        APP.events.dispatch('auth:logout');
      }, 200);

      return { name: RouteName.Home };
    }
  },
  {
    name: RouteName.Reset,
    path: '/reset',
    redirect: () => {
      /**
       * There is a theoretical risk that someone could deep-link to this
       * URL and automatically reset the app for the user. In a multi-page
       * app this would be a POST - here we need to ensure that it can
       * only be done from within the app.
       *
       * For this reason, before navigating here, you must set the
       * 'client' semaphore to 'unloading'. Unless the client semaphore
       * has that value, this controller will not be entered.
       */
      if (APP.semaphore.get('client') === 'unloading') {
        APP.shell.transmit('bank:wipe:all');
        APP.shell.transmit('roster:wipe:all');
        APP.shell.transmit('cookies:wipe:all');
        localStorage.clear();
        // Prevent any other data from being pushed into the bank
        APP.bank.freeze();
        setTimeout(() => { APP.patron.reset(); }, 200);
      }

      return { name: RouteName.Home };
    }
  },
  {
    path: '/welcome/',
    component: Welcome,
    beforeEnter: (to, from, next) => {
      if (APP.patron.isAuthenticated()) {
        next({ name: RouteName.Home });
      } else {
        next();
      }
    },
    children: [
      {
        name: RouteName.Welcome,
        path: '',
        component: LibraryCode,
        meta: {
          title: 'Welcome',
          timeline: Timeline.Onboarding
        }
      },
      {
        path: 'login/:libraryId/:callback?',
        beforeEnter: (to, from, next) => {
          if (APP.patron.isAuthenticated()) {
            next({ name: RouteName.Home });
          } else {
            next();
          }
        },
        name: RouteName.Login,
        props: true,
        component: Login,
        meta: {
          title: 'Login',
          timeline: Timeline.Onboarding
        }
      }
    ]
  },
  {
    path: '/library/:currentLibraryId/redirect/:targetLibraryId',
    name: RouteName.Redirect,
    props: (to) => {
      return {
        ...to.query,
        ...to.params
      };
    },
    component: Redirect,
    beforeEnter: (to, from, next) => {
      const target = to.query.target as string
        || {
          name: RouteName.Login,
          params: {
            libraryId: to.params.targetLibraryId
          }
        };

      // If the user's not signed in, we should take them
      // to their destination.
      if (!APP.patron.isAuthenticated()) {
        console.log('[REDIRECT] Not authenticated, proceeding...');

        next(target);

        return;
      }

      // If the user's not signed in to the "current" library,
      // we should take them to their destination.
      if (APP.library?.baseKey !== to.params.currentLibraryId) {
        console.log(`[REDIRECT] Not authenticated with ${to.params.currentLibraryId}, proceeding...`);
        next(target);

        return;
      }

      next();
    }
  },
  {
    name: RouteName.SignedOut,
    path: '/library/:libraryId/signed-out',
    component: SignedOut,
    props: (to) => {
      return {
        ...to.query,
        ...to.params
      };
    }
  },
  {
    path: '/library/:libraryId/',
    component: Arena,
    beforeEnter: (to, _, next) => {
      if (to.query.pagemoved && to.name !== RouteName.LegacyRedirectFailed) {
        next({
          name: RouteName.LegacyRedirectFailed,
          params: to.params
        });
      } else {
        next();
      }
    },
    children: [
      {
        name: RouteName.LibraryHome,
        path: '',
        component: Home,
        meta: {
          title: 'Home',
          timeline: Timeline.Home,
          top: true
        }
      },
      {
        // Legacy Search This Set route
        path: 'title/:titleSlug(\\d+)/:setSearch(.+)+',
        redirect: (route) => {
          const setSearch = route.params.setSearch;
          const searchThisTitleParams = parseSearchThisSetOptions(setSearch as string);
          const query = searchThisTitleParams
            ? mapSearchThisTitleQueryParams(searchThisTitleParams)
            : {};

          return {
            name: RouteName.TitleDetails,
            params: {
              libraryId: route.params.libraryId,
              titleSlug: route.params.titleSlug
            },
            query
          };
        }
      },
      {
        name: RouteName.TitleDetails,
        path: 'title/:titleSlug(\\d+)',
        props: (route) => {
          let searchThisTitle = null;
          if (route.query) {
            searchThisTitle = mapSearchThisTitleQueryString(
              route.query as Dictionary<string | undefined>,
              'title'
            );
          }

          return {
            ...route.params,
            searchThisTitle
          };
        },
        component: TitleDetails,
        meta: {
          title: 'Title details',
          timeline: Timeline.Home,
          breadcrumbFunction: (from: RouteLocation, to: RouteLocation) => {
            const { appendBreadcrumb, sliceBreadcrumb } = breadcrumbTrail(from, to);
            //going back to previous page by way of breadcrumbs
            if (from.meta.goneBack) {
              return sliceBreadcrumb();
            }
            //same title details page (StT)
            if (from.path === to.path) {
              return from.meta.breadcrumb;
            }
            //no ancestor (visited from link directly or refreshed) or came from login page
            //or came from a refreshed session of bifocal -> Home
            if (!from.name || from.name === RouteName.Login || from.name === RouteName.OpenBook) {
              return appendBreadcrumb({
                label: i18n.t('breadcrumbs.home'),
                name: RouteName.LibraryHome,
                singleDisplay: true
              } as Breadcrumb);
            }

            return appendBreadcrumb({
              label: i18n.t('breadcrumbs.back'),
              name: from.name,
              fullpath: from.fullPath,
              singleDisplay: true
            } as Breadcrumb);
          }
        }
      },
      {
        // Legacy Search This Set route
        path: 'set/:seriesId(\\d+)/:setSearch(.+)+',
        redirect: (route) => {
          const setSearch = route.params.setSearch;
          const searchThisTitleParams = parseSearchThisSetOptions(setSearch as string);
          const query = searchThisTitleParams
            ? mapSearchThisTitleQueryParams(searchThisTitleParams)
            : {};

          return {
            name: RouteName.SetDetails,
            params: {
              libraryId: route.params.libraryId,
              seriesId: route.params.seriesId
            },
            query
          };
        }
      },
      {
        name: RouteName.SetDetails,
        path: 'set/:seriesId(\\d+)',
        props: (route) => {
          let searchThisTitle = null;
          if (route.query) {
            searchThisTitle = mapSearchThisTitleQueryString(
              route.query as Dictionary<string | undefined>,
              'series'
            );
          }

          return {
            ...route.params,
            searchThisTitle
          };
        },
        component: SeriesDetails,
        meta: {
          title: 'Set details',
          timeline: Timeline.Home,
          breadcrumbFunction: (from: RouteLocation, to: RouteLocation) => {
            const { appendBreadcrumb, sliceBreadcrumb } = breadcrumbTrail(from, to);
            //going back to previous page by way of breadcrumbs
            if (from.meta.goneBack) {
              return sliceBreadcrumb();
            }
            //same set details page (StT)
            if (from.path === to.path) {
              return from.meta.breadcrumb;
            }
            //no ancestor (visited from link directly or refreshed) or came from login page
            //or came from a refreshed session of bifocal -> Home
            if (!from.name || from.name === RouteName.Login || from.name === RouteName.OpenBook) {
              return appendBreadcrumb({
                label: i18n.t('breadcrumbs.home'),
                name: RouteName.LibraryHome,
                singleDisplay: true
              } as Breadcrumb);
            }

            return appendBreadcrumb({
              label: i18n.t('breadcrumbs.back'),
              name: from.name,
              fullpath: from.fullPath,
              singleDisplay: true
            } as Breadcrumb);
          }
        }
      },
      {
        name: RouteName.OpenBook,
        path: 'open/:titleSlug(\\d+)',
        props: (to) => {
          return {
            ...to.query,
            ...to.params
          };
        },
        component: Open,
        meta: {
          title: 'Open book',
          breadcrumbFunction: (from: RouteLocation, to: RouteLocation) => {
            const { appendBreadcrumb } = breadcrumbTrail(from);
            //only append breadcrumbs if you know where you are coming from
            //if you don't know where (after page refresh), then don't set one
            if (from.name) {
              to.meta.goneBack = true; //can't navigate back with breadcrumbs, so storing this ahead of time

              return appendBreadcrumb({
                label: i18n.t('breadcrumbs.back'),
                name: from.name,
                fullpath: from.fullPath
              } as Breadcrumb);
            }
          }
        }
      },
      {
        name: RouteName.Browse,
        path: 'browse',
        component: Browse,
        meta: {
          title: 'Browse',
          timeline: Timeline.Browse,
          top: true
        }
      },
      {
        path: 'browse/subject-:subjectId/:searchParams(.+)+',
        redirect: (route) => {
          const searchParams = route.params.searchParams;
          const queryParams = parseLegacyListParameters(searchParams as string[]);

          const subjectLink = subjectRoute(route.params.subjectId as string);

          if ('params' in subjectLink) {
            subjectLink.params = subjectLink.params || {};
            subjectLink.params.libraryId = route.params.libraryId;
          }

          return {
            ...subjectLink,
            query: {
              sort: queryParams.sort,
              tPage: queryParams.titlePage,
              sPage: queryParams.seriesPage,
              ...queryParams.subjects
            },
            hash: '#' + queryParams.tab
          };
        }
      },
      {
        name: RouteName.Subject,
        path: 'browse/:category(subject|jurisdiction|contentType|practiceArea)/:subjectId(\\d+)-:name?',
        alias: [
          'browse/:category(subject|jurisdiction|contentType|practiceArea)/:subjectId(\\d+)'
        ],
        props: (route) => {
          return {
            subjectId: route.params.subjectId,
            name: route.params.name,
            currentSort: route.query.sort,
            appliedFilters: mapSubjectFilters(route.query as Dictionary<string | undefined>),
            titlePage: parseInt(route.query.tPage as string, 10) || 1,
            seriesPage: parseInt(route.query.sPage as string, 10) || 1
          };
        },
        component: Subject,
        meta: {
          title: 'Subject',
          timeline: Timeline.Browse,
          breadcrumbFunction: () => {
            return [
              {
                label: i18n.t('breadcrumbs.browse'),
                name: RouteName.Browse
              },
              {
                label: i18n.t('breadcrumbs.subjects'),
                name: RouteName.Subjects
              }
            ];
          }
        }
      },
      {
        name: RouteName.Subjects,
        path: 'browse/subjects',
        props: true,
        component: Subjects,
        meta: {
          title: 'Subjects',
          timeline: Timeline.Browse,
          breadcrumbFunction: () => {
            return [{
              label: i18n.t('breadcrumbs.browse'),
              name: RouteName.Browse
            }] as Breadcrumb[];
          }
        }
      },
      {
        path: 'browse/search/:searchParams(.+)+',
        redirect: (route) => {
          const searchParams = route.params.searchParams;
          const queryParams = parseLegacySearchParameters(searchParams as string[]);

          return {
            name: RouteName.Search,
            params: {
              libraryId: route.params.libraryId
            },
            query: {
              query: queryParams.query,
              sort: queryParams.sort,
              tPage: queryParams.titlePage,
              sPage: queryParams.seriesPage,
              ...queryParams.subjects
            },
            hash: '#' + queryParams.tab
          };
        }
      },
      {
        name: RouteName.Search,
        path: 'search',
        props: (route) => {
          return {
            query: route.query.query,
            currentSort: route.query.sort,
            appliedFilters: mapSubjectFilters(route.query as Dictionary<string | undefined>),
            titlePage: parseInt(route.query.tPage as string, 10) || 1,
            seriesPage: parseInt(route.query.sPage as string, 10) || 1
          };
        },
        component: Search,
        meta: {
          title: 'Search',
          timeline: Timeline.Browse,
          breadcrumbFunction: () => {
            return [{
              label: i18n.t('breadcrumbs.browse'),
              name: RouteName.Browse
            }] as Breadcrumb[];
          }
        }
      },
      {
        path: 'browse/creator-:creatorId/:searchParams(.+)+',
        redirect: (route) => {
          const searchParams = route.params.searchParams;
          const queryParams = parseLegacyListParameters(searchParams as string[]);

          return {
            name: RouteName.Creator,
            params: {
              libraryId: route.params.libraryId,
              creatorId: route.params.creatorId
            },
            query: {
              sort: queryParams.sort,
              tPage: queryParams.titlePage,
              sPage: queryParams.seriesPage,
              ...queryParams.subjects
            },
            hash: '#' + queryParams.tab
          };
        }
      },
      {
        name: RouteName.Creator,
        path: 'browse/creator/:creatorId',
        props: (route) => {
          return {
            creatorId: route.params.creatorId,
            currentSort: route.query.sort,
            appliedFilters: mapSubjectFilters(route.query as Dictionary<string | undefined>),
            titlePage: parseInt(route.query.tPage as string, 10) || 1,
            seriesPage: parseInt(route.query.sPage as string, 10) || 1
          };
        },
        component: Creator,
        meta: {
          title: 'Creator',
          timeline: Timeline.Browse,
          breadcrumbFunction: () => {
            return [{
              label: i18n.t('breadcrumbs.browse'),
              name: RouteName.Browse
            }];
          }
        }
      },
      {
        path: 'browse/publisher-:publisherId/:searchParams(.+)+',
        redirect: (route) => {
          const searchParams = route.params.searchParams;
          const queryParams = parseLegacyListParameters(searchParams as string[]);

          return {
            name: RouteName.Publisher,
            params: {
              libraryId: route.params.libraryId,
              publisherId: route.params.publisherId
            },
            query: {
              sort: queryParams.sort,
              tPage: queryParams.titlePage,
              sPage: queryParams.seriesPage,
              ...queryParams.subjects
            },
            hash: '#' + queryParams.tab
          };
        }
      },
      {
        name: RouteName.Publisher,
        path: 'browse/publisher/:publisherId',
        props: (route) => {
          return {
            publisherId: route.params.publisherId,
            currentSort: route.query.sort,
            appliedFilters: mapSubjectFilters(route.query as Dictionary<string | undefined>),
            titlePage: parseInt(route.query.tPage as string, 10) || 1,
            seriesPage: parseInt(route.query.sPage as string, 10) || 1
          };
        },
        component: Publisher,
        meta: {
          title: 'Publisher',
          timeline: Timeline.Browse,
          breadcrumbFunction: () => {
            return [{
              label: i18n.t('breadcrumbs.browse'),
              name: RouteName.Browse
            }];
          }
        }
      },
      {
        path: 'browse/:collectionType(generated|curated)-:collectionId/:searchParams(.+)+',
        redirect: (route) => {
          const searchParams = route.params.searchParams;
          const queryParams = parseLegacyCollectionParameters(searchParams as string[]);

          return {
            name: RouteName.Collection,
            params: {
              libraryId: route.params.libraryId,
              collectionId: route.params.collectionId
            },
            query: {
              sort: queryParams.sort,
              breakpoint: queryParams.breakpoint,
              page: queryParams.page,
              ...queryParams.subjects
            }
          };
        }
      },
      {
        name: RouteName.Collection,
        path: 'browse/collection/:collectionId',
        props: (route) => {
          return {
            collectionId: route.params.collectionId,
            breakpoint: route.query.breakpoint,
            currentSort: route.query.sort,
            appliedFilters: mapSubjectFilters(route.query as Dictionary<string | undefined>),
            page: parseInt(route.query.page as string, 10) || 1
          };
        },
        component: CollectionDefinition,
        meta: {
          title: 'Collection',
          timeline: Timeline.Browse,
          breadcrumbFunction: () => {
            return [{
              label: i18n.t('breadcrumbs.browse'),
              name: RouteName.Browse
            }];
          }
        }
      },
      {
        name: RouteName.SearchTitleDetails,
        path: 'browse/:search(\\D[^\\/]*)*/title/:titleSlug(\\d+)/:setSearch*',
        props: true,
        component: TitleDetails,
        meta: {
          title: 'Title details',
          timeline: Timeline.Browse
        }
      },
      {
        name: RouteName.SearchSetDetails,
        path: 'browse/:search(\\D[^\\/]*)*/set/:seriesId(\\d+)/:setSearch*',
        props: true,
        component: SeriesDetails,
        meta: {
          title: 'Set details',
          timeline: Timeline.Browse
        }
      },
      {
        path: 'mybooks/:filter(\\D[^\\/]*)*',
        redirect: () => ({ name: RouteName.MyBooks })
      },
      {
        name: RouteName.MyBooks,
        path: 'mybooks',
        props: (route) => {
          const subjectFilters = mapSubjectFilters(route.query as Dictionary<string | undefined>);

          return {
            ...route.params,
            subjectFilters
          };
        },
        component: MyBooks,
        meta: {
          title: 'My books',
          timeline: Timeline.MyBooks,
          top: true
        }
      },
      {
        path: 'notes/:filter(\\D[^\\/]*)+',
        redirect: () => ({ name: RouteName.Notes })
      },
      {
        name: RouteName.Notes,
        path: 'notes',
        props: (route) => {
          const subjectFilters = mapSubjectFilters(route.query as Dictionary<string | undefined>);
          const sort = route.query.sort;

          return {
            subjectFilters,
            sort
          };
        },
        component: Notes,
        meta: {
          title: 'My annotations',
          timeline: Timeline.Notes,
          top: true
        }
      },
      {
        name: RouteName.NoteDetails,
        path: 'notes/:filter(\\D[^\\/]*)*/:titleSlug(\\d+)',
        props: (route) => {
          const query = route.query as Dictionary<string | undefined>;

          return {
            titleSlug: route.params.titleSlug,
            colorFilters: query.color?.split(','),
            releaseFilters: (query.releaseTitleId || query.release)?.split(',')
          };
        },
        component: AnnotationDetails,
        meta: {
          title: 'Title annotations',
          timeline: Timeline.Notes,
          breadcrumbFunction: () => {
            return [{
              label: i18n.t('breadcrumbs.notes'),
              name: RouteName.Notes
            }] as Breadcrumb[];
          }
        }
      },
      {
        name: RouteName.CopyReview,
        path: 'notes/review/:jobId',
        props: true,
        component: CopyReview,
        meta: {
          title: 'Copied annotations',
          timeline: Timeline.Notes,
          breadcrumbFunction: (from: RouteLocation, to: RouteLocation) => {
            const { appendBreadcrumb, sliceBreadcrumb } = breadcrumbTrail(from, to);
            //going back to previous page by way of breadcrumbs
            if (from.meta.goneBack) {
              return sliceBreadcrumb();
            }
            //no ancestor (visited from link directly or refreshed) or came from login page
            //or came from a refreshed session of bifocal -> My annotations
            if (!from.name || from.name === RouteName.Login || from.name === RouteName.OpenBook) {
              return appendBreadcrumb({
                label: i18n.t('breadcrumbs.notes'),
                name: RouteName.Notes,
                singleDisplay: true
              } as Breadcrumb);
            }

            return appendBreadcrumb({
              label: i18n.t('breadcrumbs.back'),
              name: from.name,
              fullpath: from.fullPath,
              singleDisplay: true
            } as Breadcrumb);
          }
        }
      },
      {
        name: RouteName.Export,
        path: 'export',
        props: (route) => {
          const query = route.query as Dictionary<string | undefined>;

          return {
            colorFilters: query.color?.split(',')
          };
        },
        component: ExportQueue,
        meta: {
          title: 'Export queue',
          timeline: Timeline.Export,
          top: true
        }
      },
      {
        name: RouteName.Tags,
        path: 'tags',
        component: Tags,
        meta: {
          title: 'My tags',
          timeline: Timeline.Tags,
          top: true
        }
      },
      {
        name: RouteName.TagDetails,
        path: 'tags/:tagSlug',
        props: (route) => {
          const query = route.query as Dictionary<string | undefined>;

          return {
            ...route.params,
            subjectFilters: mapSubjectFilters(query as Dictionary<string | undefined>)
          };
        },
        component: TagDetails,
        meta: {
          title: 'Tag details',
          timeline: Timeline.Tags,
          breadcrumbFunction: () => {
            return [{
              label: i18n.t('breadcrumbs.tags'),
              name: RouteName.Tags
            }] as Breadcrumb[];
          }
        }
      },
      {
        name: RouteName.Ntc,
        path: 'videos',
        component: Ntc,
        meta: {
          title: 'Videos & more',
          timeline: Timeline.Ntc,
          top: true
        }
      },
      {
        name: RouteName.MySubscriptions,
        path: 'mysubscriptions',
        component: MySubscriptions,
        meta: {
          title: 'My Subscriptions',
          timeline: Timeline.Home,
          top: true
        }
      },
      {
        path: 'pagemoved',
        name: RouteName.LegacyRedirectFailed,
        component: LegacyRedirectFailed,
        meta: {
          title: 'Not Found'
        }
      },
      {
        path: ':pathMatch(.*)*',
        name: RouteName.NotFound,
        component: NotFound,
        meta: {
          title: 'Not Found'
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: (to) => {
      console.warn('[ROUTER] 404: Cannot find route', to.path);

      return { name: RouteName.NotFound };
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }

    APP.arena.scroller?.scrollToTop();

    return { left: 0, top: 0 };
  }
});

const configure = (vRouter: Router) => {
  vRouter.beforeEach((to, from, next) => {
    console.log(`[ROUTER] Navigating to ${to.path} from ${from.path}`);
    if (APP.updateManager.auto && to.path.indexOf('open') < 0) {
      APP.updateManager.auto = false;
    }
    APP.semaphore.set('timeline', to.meta?.timeline as Timeline | undefined);

    if (!APP.patron.isAuthenticated()
      && !(to.name === RouteName.Login || to.name === RouteName.Welcome || to.name === RouteName.SignedOut)
    ) {
      if (to.params.libraryId) {
        next({ name: RouteName.Login, params: { libraryId: to.params.libraryId }, query: { origination: to.fullPath } });
      } else {
        next({ name: RouteName.Welcome, query: { origination: to.fullPath } });
      }
    } else {
      if (APP.library && to.params.libraryId && (to.params.libraryId as string).toLowerCase() !== APP.library.baseKey.toLowerCase()) {
        next({
          name: RouteName.Redirect,
          query: {
            target: to.fullPath
          },
          params: {
            currentLibraryId: APP.library.baseKey,
            targetLibraryId: to.params.libraryId
          },
          replace: true
        });
      } else {
        //set breadcrumbs
        to.meta.breadcrumb =  to.meta.breadcrumbFunction ? (to.meta.breadcrumbFunction as Function)(from, to) : undefined;

        next();
      }
    }
  });

  vRouter.beforeEach((to, from, next) => {
    //store focus element
    findActiveElement(from, to);

    next();
  });

  vRouter.afterEach((to, from) => {
    nextTick().then(() => APP.events.dispatch('router:navigate', to));
    if (to.meta?.title !== from.meta?.title) {
      setDocumentTitle(to.meta?.title as string | undefined);
    }
  });

  vRouter.afterEach((to, from) => {
    //set focus on stored element
    setFocus(to);
  });

  vRouter.afterEach((to, from) => {
    //clear Chatterbox messages when navigating to new path
    if (to.path !== from.path) {
      APP.events.dispatch('chatter:clear');
    }
  });

  APP.events.on('bank:update', (evt) => {
    if (evt.m.key === Sentry.BANK_KEY_IDENTITY_TOKEN && !evt.m.newValue) {
      const redirectLocation = vRouter.resolve({
        name: RouteName.SignedOut,
        params: {
          libraryId: APP.library?.baseKey
        },
        query: {
          // NOTE: using raw location because some pages update the URL directly outside the router
          target: window.location.href
        }
      });

      window.location.replace(redirectLocation.href);
    }
  });

  return vRouter;
};


export default configure(router);
