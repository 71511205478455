<template>
  <main
    :id="skipLinkMainHash?.slice(1)"
    ref="view"
    tabindex="-1"
    :aria-label="$t('nav.skipLink.main.target')"
    :class="$style.surface"
  >
    <div
      ref="scroller"
      class="scroller"
    >
      <slot></slot>
    </div>
  </main>
</template>

<script lang="ts">
import { APP } from 'app/base/app';
import { C } from 'app/base/common';
import { SkipLinkMainSymbol } from 'app/keys/injection-keys';
import { Scroller } from 'app/views/core/scroller';
import { ContactHandlerState } from 'lib/gala/src/contact-handler';
import Quirks from 'lib/gala/src/quirks';
import { defineComponent, inject, onMounted, ref, watch } from 'vue';
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router';

export default defineComponent({
  name: 'Surface',
  setup: (_, ctx) => {
    const view = ref<HTMLElement | null>(null);
    const scroller = ref<HTMLElement | null>(null);
    const route = useRoute();
    const skipLinkMainHash = inject(SkipLinkMainSymbol);

    onMounted(() => {
      if (view.value && !Quirks.ask('ios-safari')) {
        APP.events.onContact({
          start: (evt) => onSlideStart(view.value!, evt, route),
          move: (evt) => onSlideMove(view.value!, evt),
          end: (evt) => onSlideEnd(view.value!, evt),
          cancel: () => onSlideCancel(view.value!)
        },
        view.value,
        { cancelOnLeave: true });
      }

      if (!scroller.value) { return; }
      APP.arena.scroller = new Scroller(scroller.value);
    });

    // Flash the background color when the skip link is clicked
    // to indicate focus has moved to the main content
    watch(() => route.hash, () => {
      if (route.hash === skipLinkMainHash && scroller.value) {
        scroller.value.style.backgroundColor = 'var(--c-focus)';
        setTimeout(() => {
          if (scroller.value) {
            scroller.value.style.backgroundColor = '';
          }
        }, 1000);
      }
    });

    return {
      scroller,
      skipLinkMainHash,
      view
    };
  }
});

let state: ContactHandlerState | null = null;

function onSlideStart(target: HTMLElement, evt: PointerEvent, route?: RouteLocationNormalizedLoaded): void {
  if (route?.meta?.top) { return; }
  if (evt.pageX > 16) { return; }
  APP.events.stop(evt);
  state = {};
  state.startX = evt.pageX;
  state.slidePosition = state.startPosition = target.getBoundingClientRect().left;
}


function onSlideMove(target: HTMLElement, evt: PointerEvent): void {
  if (!state) { return; }
  const deltaX = evt.pageX - (state.startX || 0);
  slideToPosition(target, (state.startPosition || 0) + deltaX, true);
}


function onSlideEnd(target: HTMLElement, evt: PointerEvent): void {
  if (!state || state.slidePosition === undefined || state.prevPosition === undefined) {
    return;
  }
  APP.events.stop(evt);
  if (state.slidePosition + 5 > state.prevPosition) {
    onSlideCancel(target);
    APP.nav.back();
    state = null;
  } else {
    onSlideCancel(target);
  }
}


function onSlideCancel(target: HTMLElement): void {
  if (!state) { return; }
  slideToPosition(target, (state.startPosition || 0));
  state = null;
}


function slideToPosition(target: HTMLElement, pos: number, immediate?: boolean): void {
  if (!state) { return; }

  state.prevPosition = state.slidePosition;
  state.slidePosition = pos;
  if (immediate && !state.immediate) {
    C.prefixProperty(target.style, 'transition-duration', '0ms');
    state.immediate = true;
  } else if (state.immediate && !immediate) {
    C.prefixProperty(target.style, 'transition-duration', '260ms');
    state.immediate = false;
  }
  C.prefixProperty(target.style, 'transform', `translate3d(${pos}px, 0, 0)`);
}
</script>

<style lang="less" module>
@import '../../app/views/core/base.less';

.surface {
  position: absolute;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;

  z-index: 25;
}

.surface {
  background-color: @c-white;

  :global {
    .scroller {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      box-sizing: border-box;
      backface-visibility: hidden;
      padding: 1rem;
      background-color: var(--c-darkest-blue);
      transition: background-color 300ms;

      display: flex;
      flex-direction: column;

      @media screen and (max-width: @px-vp-hide-nav) {
        padding: .5rem;
      }

    }

    .content {
      background-color: @c-white;
      // Top left+right border-radius is smaller to hide it where .page-header is on top of .content
      border-radius: .625rem .625rem .5rem .5rem;
      flex: 1;
    }
  }
}
</style>
