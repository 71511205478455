<template>
  <button
    @click="copy"
  >
    <div>
      <Icon name="copy-link-to-clipboard" />
    </div>
    <span>{{ buttonText }}</span>
  </button>
</template>

<script lang='ts'>
import { ClipboardHelper } from 'app/base/clipboard';
import { announceMessage } from 'app/functions/use-chatterbox';
import { useI18n } from 'app/functions/use-i18n';
import { computed, defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'CopyLink',
  props: {
    link: {
      type: String,
      default: undefined
    },
    linkFunction: {
      type: Function,
      default: undefined
    }
  },
  setup: (props) => {
    const getLinkFromLocation = () => location.href.replace('nautilus-', '');

    const copied = ref(false);

    const copy = async () => {
      let linkToCopy = getLinkFromLocation();
      if (props.link) {
        linkToCopy = props.link;
      } else if (props.linkFunction) {
        linkToCopy = await props.linkFunction();
      }

      // setTimeout is hack for iOS & Safari
      // to allow copy after an API request is made
      setTimeout(async () => {
        const success = await ClipboardHelper.copyToClipboard(linkToCopy);

        if (success) {
          copied.value = true;
          window.setTimeout(() => copied.value = false, 3000);
        }
      }, 0);
    };

    const { t } = useI18n();
    const buttonText = computed(() => copied.value ? t('copyLink.copied') : t('copyLink.label'));

    watch(buttonText, () => {
      announceMessage(buttonText.value);
    });

    return {
      buttonText,
      copy
    };
  }
});
</script>

