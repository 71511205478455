<template>
  <div class="visually-hidden">
    <div
      ref="politeRegion"
      aria-live="polite"
    >
      {{ politeMessage }}
    </div>
    <div
      ref="assertiveRegion"
      aria-live="assertive"
    >
      {{ assertiveMessage }}
    </div>
  </div>
</template>

<script lang='ts'>
import { C } from 'app/base/common';
import { useAppEvents } from 'app/functions/use-app-events';
import { ChatterboxMessage, ChatterboxMessageType } from 'app/functions/use-chatterbox';
import { Dictionary } from 'lib/common/dictionary';
import { defineComponent, nextTick, ref } from 'vue';

export default defineComponent({
  name: 'Chatterbox',
  setup: (props, ctx) => {
    const politeRegion = ref<HTMLElement | null>(null);
    const assertiveRegion = ref<HTMLElement | null>(null);
    const politeMessage = ref<string>('');
    const assertiveMessage = ref<string>('');
    const messageQueue = ref<Dictionary<ChatterboxMessage>>({});

    const modifyIfSame = (oldMsg: string, newMsg: string) => {
      return oldMsg === newMsg ? newMsg.concat('\u00A0') : newMsg;
    };

    const addToQueue = (msg: ChatterboxMessage) => {
      messageQueue.value[msg.id] = msg;
    };

    const removeFromQueue = async (msgId: string) => {
      await nextTick();
      delete messageQueue.value[msgId];
    };

    const readFromQueue = (queue: ChatterboxMessage[], index = 0) => {
      const item = queue[index];

      const modified = modifyIfSame(politeMessage.value, item.message);
      politeMessage.value = modified;

      removeFromQueue(item.id);

      setTimeout(() => {
        if (index < queue.length - 1) {
          // read next item after a delay
          readFromQueue(queue, index + 1);
        }
      }, 1000);
    };

    const announcePoliteMessage = (msg: ChatterboxMessage) => {
      addToQueue(msg);
      announceQueueSoon();
    };

    const announceAssertiveMessage = (message: string) => {
      const modified = modifyIfSame(assertiveMessage.value, message);
      assertiveMessage.value = modified;
    };

    const sortMessages = (messages: ChatterboxMessage[]) => {
      const priority: { [key in ChatterboxMessageType]: number } = {
        title: 0,
        count: 1,
        update: 2,
        other: 2
      };

      return messages.slice().sort((a, b) => priority[a.type] - priority[b.type]);
    };

    // allow incoming messages to delay announcement. Sort messages by priority before reading
    const announceQueueSoon = C.debounce(() => { readFromQueue(sortMessages(Object.values(messageQueue.value))); }, 1000);

    useAppEvents({
      'chatter:clear': () => {
        // clear out the live regions so the text isn't accessible to screen readers
        // but don't clear out the messages, so we can still use them to compare with the new ones
        if (politeRegion.value) {
          politeRegion.value.innerHTML = '';
        }
        if (assertiveRegion.value) {
          assertiveRegion.value.innerHTML = '';
        }

      },
      'chatter:polite': (evt) => announcePoliteMessage(evt.m),
      'chatter:assertive': (evt) => announceAssertiveMessage(evt.m)
    });

    return {
      assertiveMessage,
      assertiveRegion,
      politeMessage,
      politeRegion
    };
  }
});
</script>
