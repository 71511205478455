import { C } from 'app/base/common';
import { generateUUID } from 'lib/common';
import { ComputedRef, Ref, ref, watch } from 'vue';
import Events from '../../app/events/events';

export type ChatterboxMessageType = 'title' | 'count' | 'update' | 'other';

export type ChatterboxMessage = {
  message: string;
  id: string;
  type: ChatterboxMessageType;
};


/**
 * Announce a single message to the screen reader.
 * Will allow you to announce the same message twice.
 * If polite, will be added to the message queue and announce after a short delay.
 * If assertive, will announce right away. Loading messages should use this.
 *
 * @param message string to be announce to the screen reader
 * @param politeness 'polite' or 'assertive' depending on the importance of the message
 */
export function announceMessage(message: string, politeness?: 'polite' | 'assertive') {
  if (politeness === 'assertive') {
    Events.dispatch('chatter:assertive', message);
  } else {
    const id = `chatterbox-new-${generateUUID()}`;
    Events.dispatch('chatter:polite', {
      id,
      message,
      type: 'other'
    });
  }
}


/**
 * Keep track of a message that may change and need to be reannounced.
 * Assumes the message will not be the same as before.
 *
 * @param message the message to watch and reannounce after a change happens
 */
export function watchMessage(message: ComputedRef<string>) {
  const id = `chatterbox-update-${generateUUID()}`;
  watch(() => message.value, () => {
    Events.dispatch('chatter:polite', {
      id,
      message: message.value,
      type: 'update'
    });
  });
}


/**
 * Announce result count after text filtering.
 * Holds off until updates have stopped for 1 second.
 *
 * @param textFilter the model value of the text filter
 * @param showingCount string to announce
 */
export function watchTextFiltering(textFilter: Ref<string, string>, showingCount: ComputedRef<string>) {
  let typingTimer = -1;
  watch(() => textFilter.value, () => {
    clearTimeout(typingTimer);
    typingTimer = window.setTimeout(() => {
      Events.dispatch('chatter:polite', {
        id: 'chatterbox-result-count',
        message: showingCount.value,
        type: 'count'
      });
    }, 1000);
  });
}


/**
 * Announce result count after filtering.
 * Must use the shouldAnnounceCount for this to work. That way announcements happen after an interaction rather than any object change (page loads).
 * Have to watch one of the final objects to be updated, or the showingCount will be most likely incorrect (pre-filtered value)
 *
 * @param toWatch what to watch for changes. If boolean, false will not announce anything, true will. If object, will announce after any update.
 * @param showingCount string to announce
 * @returns shouldAnnounceCount. Modify this in the component to signify an announcement should be made.
 */
export function watchFiltering(toWatch: Ref, showingCount: ComputedRef<string>) {
  const shouldAnnounceCount = ref(false);

  const setFalseSoon = C.debounce(() => { shouldAnnounceCount.value = false; }, 500);

  watch(() => toWatch.value, () => {
    if (!toWatch.value) {
      // ignore false values
      return;
    }

    if (shouldAnnounceCount.value) {
      Events.dispatch('chatter:polite', {
        id: 'chatterbox-result-count',
        message: showingCount.value,
        type: 'count'
      });

      // small delay to allow for any successive updates to overwrite the old message
      setFalseSoon();
    }

  });

  return {
    shouldAnnounceCount
  };
}


/**
 * Announce the title of the page.
 * Chatterbox will handle cases where the  title of page changes while on the page (list pages: 'Loading' > list name), or title is repeated
 * as long as the changes happen in quick enough succession.
 *
 * @param title string
 */

export function announcePageTitle(title: string) {
  Events.dispatch('chatter:polite', {
    id: 'chatterbox-page-title',
    message: title,
    type: 'title'
  });
}
