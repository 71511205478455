import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "aria-label"]
const _hoisted_2 = {
  ref: "scroller",
  class: "scroller"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("main", {
    id: _ctx.skipLinkMainHash?.slice(1),
    ref: "view",
    tabindex: "-1",
    "aria-label": _ctx.$t('nav.skipLink.main.target'),
    class: _normalizeClass(_ctx.$style.surface)
  }, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ], 512)
  ], 10, _hoisted_1))
}